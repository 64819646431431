import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { useState } from "react";
import moment from "moment";
import App, { isSeller } from "../App";
import { user_has_group } from "../util.js";
import { retrieveApprovationQueue } from './components/evaluation.tsx';
import { ModalOutputBox } from "./components/active_eval.tsx";

const convert_moment = (moment) => {
    return moment.format("YYYY-MM-DD HH:mm:ss")
}

export function Queue(user) {

    // States to keep track of the user input
    const [intervalType, setIntervalType] = useState("between");
    const [operator, setOperator] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [entriesList, setEntriesList] = useState(new Array());
    const [page, setPage] = useState(0);
    const [selectPage, setSelectPage] = useState(0);
    const [limit, setLimit] = useState(false);
    const [availableOperators, setAvailableOperators] = useState(new Array());
    const didAskForUsersRef = useRef(false);
    const [adminUserName, setAdminUserName] = useState("");
    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");
    const [adminAllUsers, setAdminAllUsers] = useState(false);
    const [updatingOperatorsList, setUpdatingOperatorsList] = useState(false);
    const [vinSearch, setVinSearch] = useState("");
    const [vinSearchCheck, setVinSearchCheck] = useState(false);
    // Used to keep track of the active evaluation to show
    const [activeEvaluationDetail, setActiveEvaluationDetail] = useState(null);
    const [activeIndex, setActiveIndex] = useState(1);
    const [searchPlate, setSearchPlate] = useState("");
    const [dateNow, setDateNow] = useState(moment().format("YYYY-MM-DD"));
    const onlyApproved = useRef(false);
    let token = user.user.getSignInUserSession().getIdToken().getJwtToken();
    let seller = isSeller(user.user);
    const to = moment(moment(endDate).valueOf()).utc();

    const clamp = (val, min, max) => Math.min(Math.max(val, min), max);

    function onPageIncrement() {
        if (selectPage >= page) {
            handleSubmit();
        } else {
            setSelectPage(clamp(selectPage + 1, 1, page));
        }
    }

    function onPageDecrement() {
        setSelectPage(clamp(selectPage - 1, 1, page));
    }

    function onPageInputChange(evt) {
        setSelectPage(clamp(evt.target.value, 1, page));
    }

    function onActiveEvaluationClose() {
        setActiveEvaluationDetail(null);
        setActiveIndex(1);
    }

    useEffect(() => {
        if (user_has_group(user.user, "BackOfficeRA")) {
            onlyApproved.current = true
        }
        const fetchData = async () => {
            setIsSearching(true);
            try {
                retrieveApprovationQueue(token, page.toString(), (resp) => {
                    setEntriesList([resp]);
                    setPage(1);
                    setSelectPage(1);
                    let entries = Object.entries(resp);
                    if (entries.length > 0) {
                        let lastEntry = entries[entries.length - 1][1];
                        let lastDate = moment(lastEntry.evaluations_details.rebounce.evaluation.time);
                        let now = moment();
                        let diff = now.diff(lastDate, 'days');
                        if (diff > 30) {
                            setLimit(true);
                        }
                        if (entries.length < 10) {
                            setLimit(true);
                        }
                    }
                }, searchPlate, onlyApproved.current);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsSearching(false);
            }
        };
        fetchData();
    }, []);

    // Callback for submit button press
    function handleSubmit() {
        if (limit) {
            return;
        }
        setIsSearching(true);
        // Retrieve user token
        try {
            retrieveApprovationQueue(token, page.toString(), (resp) => {
                setEntriesList(prevEntries => [...prevEntries, resp]);
                setPage(page + 1);
                setSelectPage(page + 1);
                let entries = Object.entries(resp);

                // Check if we exceeded 30 days from today
                let lastEntry = entries[entries.length - 1][1];

                let lastDate = moment(lastEntry.evaluations_details.rebounce.evaluation.time);
                let now = moment();
                let diff = now.diff(lastDate, 'days');
                if (diff > 30) {
                    setLimit(true);
                }

                // If we have less than 10 elements, we surely hit the limit
                if (entries.length < 10) {
                    setLimit(true);
                }
            }, searchPlate, onlyApproved.current);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsSearching(false);
        }
    }


    function handleSearchChange(evt) {
        let searchValue = evt.target.value;
        setIsSearching(true);
        setSearchPlate(searchValue);
        try {
            retrieveApprovationQueue(token, "0", (resp) => {
                setPage(1);
                setSelectPage(1);
                setEntriesList([resp]);
                let entries = Object.entries(resp);
                // Check if we exceeded 30 days from today
                let lastEntry = entries[entries.length - 1][1];
                let lastDate = moment(lastEntry.evaluations_details.rebounce.evaluation.time);
                let now = moment();
                let diff = now.diff(lastDate, 'days');
                if (diff > 30) {
                    setLimit(true);
                }
            }, searchValue, onlyApproved.current);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsSearching(false);
        }
    }


    function updateState(selectPage, activeIndex, status) {
        let newEntries = [...entriesList];
        if (newEntries[selectPage - 1]) {
            let entry = { ...newEntries[selectPage - 1][activeIndex] };
            entry.queue_details.stato = status;
            newEntries[selectPage - 1][activeIndex] = entry;
            setEntriesList(newEntries);
        }
    }

    // Conditional printing
    let entries: null | React.ReactElement;
    if (selectPage > 0) {
        const id = selectPage - 1;

        if (entriesList && entriesList[id]) {
            entries =
                entriesList[id].map((e, i: number) => {
                    let evaluation_details = e.evaluations_details;

                    // Report Reliability when present
                    let reliability = '-';
                    try {
                        reliability = `${(evaluation_details.laCompriamoNoi.reliability.value * 100.).toFixed(0)}%`;
                    } catch { }

                    let stato = e.queue_details.stato;
                    let class_stato = stato === "In attesa" ? "waiting" : stato === "Rifiutata" ? "rejected" : "confirmed";
                    let queue_details = e.queue_details;
                    let time = moment(evaluation_details.rebounce.evaluation.time).format("YYYY-MM-DD HH:mm:ss");
                    const lcn = queue_details.prezzo_confermato || (evaluation_details && evaluation_details.laCompriamoNoi && evaluation_details.laCompriamoNoi.evaluation && evaluation_details.laCompriamoNoi.evaluation.value);
                    const eval_dmg = queue_details && Math.floor(parseInt(queue_details.stima_danni) / 10) * 10 || "-";
                    const eval_maxval = queue_details && Math.floor(parseInt(queue_details.prezzo_confermato) / 10) * 10 || "-";
                    const eval_val = typeof eval_dmg === "number" && typeof eval_maxval === "number" && eval_dmg + eval_maxval || "-";
                    const autobro_val = evaluation_details && evaluation_details.autobro && evaluation_details.autobro.evaluation ? evaluation_details.autobro.evaluation.value : '-';
                    const marca = evaluation_details && evaluation_details.vehicleDesc && evaluation_details.vehicleDesc.marca ? evaluation_details.vehicleDesc.marca : "-";
                    const model = evaluation_details && evaluation_details.vehicleDesc && evaluation_details.vehicleDesc.modello ? evaluation_details.vehicleDesc.modello : "-";
                    const version = evaluation_details && evaluation_details.vehicleDesc && evaluation_details.vehicleDesc.version ? evaluation_details.vehicleDesc.version : "-";
                    return <tr key={`row-${i}`} className="report-row" style={{ 'cursor': 'pointer' }} onClick={(evt) => { setActiveEvaluationDetail(e); setActiveIndex(i) }}>
                        {operator.length > 0 || <td key={`user-${i}`} className="operator"><span title={queue_details.user}>{queue_details.user}</span></td>}
                        <td key={`timestamp-${i}`}>{time}</td>
                        <td key={`plate-${i}`}>{(evaluation_details.rebounce.plate && evaluation_details.rebounce.plate.toUpperCase()) || "-"}</td>
                        <td key={`regdate-${i}`}>{evaluation_details.rebounce.registrationDate || "-"}</td>
                        {/* <td key={`motornet-${i}`}>{evaluation_details.codici.motornetUnivoco || "-"}</td> */}
                        <td key={`km-${i}`}>{evaluation_details.rebounce.km || "-"}</td>
                        <td key={`brand-${i}`}>{marca}</td>
                        <td key={`model-${i}`}>{model}</td>
                        <td key={`version-${i}`}>{version}</td>
                        {!user_has_group(user.user, "Limited") &&
                            <td className="valore-di-atto" key={`eval-val-${i}`}>{lcn && (!user_has_group(user.user, "RichiesteAvanzate") || stato === "Approvata") && Math.floor(parseInt(eval_val) / 10) * 10 || "-"}</td>
                        }
                        {!user_has_group(user.user, "Limited") &&
                            <td className="stima-danni" key={`eval-dmg-${i}`}>{lcn && (!user_has_group(user.user, "RichiesteAvanzate") || stato === "Approvata") && Math.floor(parseInt(eval_dmg) / 10) * 10 || "-"}</td>
                        }
                        {!user_has_group(user.user, "Limited") &&
                            <td className="massimo-valore-di-atto" key={`eval-maxval${i}`}>{lcn && (!user_has_group(user.user, "RichiesteAvanzate") || stato === "Approvata") && Math.floor(parseInt(eval_maxval) / 10) * 10 || "-"}</td>
                        }
                        {
                            !user_has_group(user.user, 'Limited') &&
                            ((user_has_group(user.user, 'ApprovazioneRichieste') || user_has_group(user.user, 'BackOfficeRA'))) &&
                            <td>{autobro_val}</td>
                        }

                        {/* <td className={`reliability-${i}`} key={`reliability-${i}`}>{reliability}</td> */}
                        < td key={`state-${i}`} className='evaluation-state ' >
                            <span className={'state ' + class_stato}>
                                {stato}
                            </span>
                        </td >
                    </tr >
                });
        } else {
            entries = <Spinner></Spinner>;
        }
    } else {
        entries = null;
    };

    // Add the `success` object to an element
    type EvaluationElem = {
        success: JSON
    }

    const evalelem: EvaluationElem = {
        success: activeEvaluationDetail?.evaluations_details
    }

    return activeEvaluationDetail ?
        <ModalOutputBox
            user={user}
            evaluationDetails={evalelem}
            queueDetails={activeEvaluationDetail?.queue_details}
            activeEvaluationDetail={activeEvaluationDetail}
            onClose={onActiveEvaluationClose}
            onUpdate={updateState}
            selectPage={selectPage}
            activeIndex={activeIndex}
        /> :
        <>
            <h6>
                Ricerca per Targa
            </h6>
            <InputGroup className="mb-3">
                <Form.Control
                    type="search"
                    placeholder="Cerca..."
                    aria-label="Cerca"
                    onChange={handleSearchChange}
                />
            </InputGroup>
            <Form>
                <Container className="overflow-auto">
                    <Table className="queue-table">
                        <thead>
                            <tr key="table-header">
                                {operator.length > 0 || <th key="user">Utente</th>}
                                <th key="timestamp">Data</th>
                                <th key="plate">Targa</th>
                                <th key="registration-date">Immatricolazione</th>
                                {/* <th key="motornet">Motornet</th> */}
                                <th key="km">KM</th>
                                <th key="brand">Marca</th>
                                <th key="model">Modello</th>
                                <th key="version">Allestimento</th>
                                {!user_has_group(user.user, "Limited") && <th key="eval-val">Valutazione di ritiro</th>}
                                {!user_has_group(user.user, "Limited") && <th key="eval-dmg">Stima Danni</th>}
                                {!user_has_group(user.user, "Limited") && <th key="eval-maxval">Massimo Valore di Atto</th>}
                                {!user_has_group(user.user, 'Limited') && ((user_has_group(user.user, 'ApprovazioneRichieste') || user_has_group(user.user, 'BackOfficeRA'))) &&
                                    <th key="autobro-val">Valore Autobro</th>
                                }

                                {/* <th key="reliability">Affidabilità</th> */}
                                <th key="state">Stato</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entries}
                        </tbody>
                    </Table>
                </Container>
                {entriesList.length > 0 && <Form.Group className="listing-nav">
                    <Row>
                        <Col style={{ textAlign: "right" }}><Button onClick={onPageDecrement} disabled={selectPage <= 1}>{`<`}</Button></Col>
                        <Col style={{ width: 10 }}><Form.Control type="number" style={{ textAlign: "center" }} min={1} value={selectPage || 0} onChange={onPageInputChange} /></Col>
                        <Col style={{ textAlign: "left" }}><Button onClick={onPageIncrement} disabled={isSearching || limit && selectPage >= page}>{'>'}</Button></Col>
                    </Row>
                </Form.Group>}
            </Form >
        </>
}
